.navbar {
  width: 100%;
  margin-bottom: 5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f0f0f0;
  padding: 10px 6%;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .navbar img { max-height: 50px }
}
