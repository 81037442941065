.container {
  min-height: 60%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .container {
    min-height: 100%;
  }
}

.container div {
  width: 100%;
  height: 100%;
}
